import request from '@/lib/request'

/**
 * 获取网页授权用户openid
 * @param {string} data.appId
 * @param {string} data.code
 * @returns {AxiosPromise}
 */
export function getApiOpenId(data) {
  return request({
    url: '/microService/mkk-msgs-server/mpUser/getOpenId',
    method: 'post',
    baseURL: false,
    params:data,
    timeout: 60000 // 60秒
  })
}

/**
 * 判断是否关注公众号
 * @param {string} data.appId
 * @param {string} data.openId
 * @returns {AxiosPromise}
 */
export function isSubscribe(data) {
  return request({
    url: '/microService/mkk-msgs-server/mpUser/isSubscribe',
    method: 'post',
    baseURL: false,
    params:data,
    timeout: 60000 // 60秒
  })
}
