/**
 * @Name: wechat.js
 * @Author: MKK
 * @Date: 2022/7/6 16:41
 */
import config from "@/lib/config";
import {getApiOpenId} from "@/api/wechat/base";

// 判断是否是小程序(注意现在改成阻塞等待方式判断了,没有判断完之前不会往下执行.所以在使用时切忌不要耽搁渲染时间)
// 调用方法时 比如也是 async 方法名 否则无法达到阻塞异步的效果
export async function isWxMin () {
    return new Promise(function (resolve) {
        var ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            // ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
            if (typeof wx !== 'undefined') {
                wx.miniProgram.getEnv((res) => {
                    if (res.miniprogram) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                });
            } else {
                resolve(false);
            }
        } else {
            resolve(false);
        }
    });
}
// 判断是否是微信环境
export  function isWeiXin () {
    let ua = navigator.userAgent.toLowerCase();
    let res = ua.match(/MicroMessenger/i);
    if (res !== null && res.toString() === 'micromessenger') {
        return true;
    } else {
        return false;
    }
}
// 获取code
export  function getWechatWebCode() {
    let authorize_tag = 'webapp_follow_test_my'; // 给nginx做转发回调的
    if (process.env.VUE_APP_ENV == 'production') {
        // 生产环境
        authorize_tag = 'webapp_follow';
    }
    let redirect_url = `https://${config.authorize_domain}/${authorize_tag}/` + location.search;
    let href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + config.appid + '&redirect_uri=' +
        encodeURIComponent(redirect_url) + '&response_type=code&scope=snsapi_base#wechat_redirect';
    window.location.href = href;
}
// 获取openid
export function getOpenId(data, callback) {
    getApiOpenId(data).then((res) => {
        callback(res);
    });
}
// 根据code获取access_token
export  function getAccessToken(code, callback) {

}

