/**
 * @Name: config
 * @Author: MKK
 * @Date: 2022/7/6 15:55
 */

module.exports = {
    appid: 'wx245a6452cb86123d', // 微信公众号appid
    authorize_domain: 'h5.makuku.com', // 授权域名
}
