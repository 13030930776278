<template>
  <div class="page">
    <div class="middle">
      <img class="follow-title" :src="followTitle" />
      <img class="follow-bg-01" :src="followBg1" />
      <div class="content">
        <img class="follow-bg-02" :src="followBg2" />
        <div class="follow-qr-code">
          <img class="code" :src="qrCode" />
          <p class="h4">可长按扫码</p>
          <p class="h5">关注公众号</p>
        </div>
      </div>
    </div>
    <div class="but-list">
      <div class="but" @click="verifyConcerns()" >我已经关注啦</div>
<!--      <div class="but" @click="openUrl()" >点击关注公众号</div>-->
    </div>
  </div>
</template>
<script>
import qrCode from "@/assets/wechat/qrcode.jpg";
import followBg1 from "@/assets/wechat/follow_bg_01.png";
import followBg2 from "@/assets/wechat/follow_bg_02.png";
import followTitle from "@/assets/wechat/follow_title.png";
import { mapGetters, mapMutations } from 'vuex'
import {getWechatWebCode, getOpenId, isWxMin} from "@/lib/wechat";
import {isSubscribe} from "@/api/wechat/base";
import { Toast } from "vant";
import wechatConfig from "@/lib/config";
export default{
  name: 'follow',
  components: {
  },
  data () {
    return {
      qrCode: qrCode, // 二维码图片
      followBg1: followBg1,
      followBg2: followBg2,
      followTitle: followTitle,
      urlData: {} // 地址栏参数
    };
  },
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters([
      'openid'
    ])
  },
  props: [],
  methods: {
    ...mapMutations(['updateOpenid']),
    // 打开文章
    openUrl() {
      location.href = "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=Mzk0MjE5NTgwMA==#wechat_redirect";
    },
    // 验证关注
    verifyConcerns() {
      if (this.openid != '') {
        isSubscribe({
          appId: wechatConfig.appid,
          openId: this.openid
        }).then( async (res) => {
          if (res.data) {
              if (await isWxMin()) {
                wx.miniProgram.postMessage({ data: { followed: 1 } })
                // 小程序环境
                wx.miniProgram.navigateBack();
              } else if (this.mkk_back_url !== undefined) {
                // 普通H5页面回跳
                location.href = this.mkk_back_url + (this.mkk_back_url.indexOf('?') ? '&' : '?') + `openid=${res.data}`;
              }
          } else {
            Toast("请先关注微信公众号");
          }
        })
      } else {
        Toast("请重新打开此页面");
      }
    },
    async handlePopstate() {
      if (await isWxMin()) {
        // 小程序环境
        wx.miniProgram.navigateBack();
      } else if (this.mkk_back_url !== undefined) {
        // 普通H5页面回跳
        location.href = this.mkk_back_url + (this.mkk_back_url.indexOf('?') ? '&' : '?') + `openid=${res.data}`;
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.handlePopstate)
  },
  mounted () {
    // 生命周期挂载之后
    // this.$route.query.code = '001WQ7000jjY8O1VAb000PzjI04WQ70m';
    window.addEventListener('popstate', this.handlePopstate)
    this.urlData = this.$route.query;
    if (this.urlData.openid) {
      // 已经拿到openid了
      this.updateOpenid(this.urlData.openid);
    } else if (this.urlData.code !== undefined && this.urlData.code.length > 20 && this.openid == '') {
      history.pushState(null, null, location.href); // 添加历史记录
      getOpenId({
        userId: this.urlData.userId,
        token: this.urlData.token,
        code: this.urlData.code,
        appId: wechatConfig.appid
      }, (res) => {
        //   let res = {"code":200,"data":"oWCL56m5hMucWoUoEgPgPzhzgOZE","msg":"ok","path":null,"timestamp":"1657158664184","errorMsg":""};
        this.updateOpenid(res.data);
        history.pushState(null, null, location.href + "&openid=" + res.data); // 添加历史记录
      });
    } else if (this.openid == '') {
      getWechatWebCode();
    }
  }
};
</script>
<style lang="less" scoped>
  .page {
    min-height: 100vh;
    padding-bottom: 50px;
    box-sizing: border-box;
    background-color: #fff;
    .middle {
      position: relative;
      .follow-title {
        top: 49px;
        position: absolute;
        width: 200px;
        left: 50%;
        transform: translateX(-50%);
      }
      .follow-bg-01 {
        width: 100%;
        display: block;
      }
      .content {
        position: relative;
        font-family: PingFangSC-Medium, PingFang SC;
        .follow-bg-02 {
          display: block;
          width: 100%;
        }
        .follow-qr-code {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          text-align: center;
          color: #fff;
          .code {
            width: 120px;
            height: 120px;
          }
          .h4 {
            font-size: 15px;
            margin-top: 7px;
            font-weight: bold;
          }
          .h5 {
            font-size: 12px;
          }
        }
      }


    }
    .but-list {
      margin: 50px 36px 0;
      display: flex;
      justify-content: center;
      .but {
        //flex: 1;
        text-align: center;
        font-size: 15px;
        color: #fff;
        border-radius: 22px;
        height: 44px;
        line-height: 44px;
        font-weight: bold;
        width: 180px;
        &:nth-child(1) {
          color: #DE2727;
          border: 2px solid #DE2727;
        }
        &:nth-child(2) {
          margin-left: 24px;
          background-color: #DE2727;
        }
      }
    }
  }
</style>
